import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import EC2Pricing from './EC2Pricing';
import UserPool from './cognitoConfig';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = () => {
    const username = prompt('Enter your username:');
    const password = prompt('Enter your password:');
  
    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });
  
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
  
    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        console.log('Login successful:', result);
        setIsLoggedIn(true);
        navigate('/pricing'); // Redirect to the pricing page after successful login
      },
      onFailure: (err) => {
        console.error('Login failed:', err);
        setError('Login failed. Please try again.');
      },
      newPasswordRequired: () => {
        const newPassword = prompt('Enter a new password:');
        // Pass an empty object for user attributes to avoid modifying email or other immutable attributes
        user.completeNewPasswordChallenge(newPassword, {}, {
          onSuccess: (result) => {
            console.log('Password changed successfully:', result);
            setIsLoggedIn(true);
            navigate('/pricing'); // Redirect after password change
          },
          onFailure: (err) => {
            console.error('Password change failed:', err);
            setError('Password change failed. Please try again.');
          },
        });
      }
    });
  };  

  const handleLogout = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
      setIsLoggedIn(false);
      navigate('/'); // Redirect to home on logout
    }
  };

  useEffect(() => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (session && session.isValid()) {
          setIsLoggedIn(true);
        }
      });
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <Routes>
          <Route
            path="/"
            element={
              !isLoggedIn ? (
                <div>
                  <h2>Welcome to Infrastructure Manager</h2>
                  <button onClick={handleLogin}>Login</button>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
              ) : (
                <Navigate to="/pricing" replace />
              )
            }
          />
          <Route
            path="/pricing"
            element={
              isLoggedIn ? (
                <div>
                  <button onClick={handleLogout}>Logout</button>
                  <EC2Pricing />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
        </Routes>
      </header>
    </div>
  );
}

export default App;
