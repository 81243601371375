import React, { useEffect, useState } from 'react';
import { Table, Button, Form, Container, Row, Col } from 'react-bootstrap';

function EC2Pricing() {
  const [pricingData, setPricingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const itemsPerPage = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortKey, setSortKey] = useState('instanceType');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    async function fetchPricing(nextToken = null) {
        try {
          const apiUrl = process.env.REACT_APP_API_BASE_URL;
          if (!apiUrl) {
            throw new Error("API URL is not defined");
          }
      
          const url = nextToken ? `${apiUrl}?nextToken=${nextToken}` : apiUrl;
          const response = await fetch(url);
      
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
          }
      
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            const data = await response.json();
            const parsedData = JSON.parse(data.body);
      
            setPricingData(prevData => [...prevData, ...parsedData]);
      
            if (data.nextToken) {
              fetchPricing(data.nextToken);
            } else {
              setLoading(false);
            }
          } else {
            throw new Error("Response is not JSON");
          }
        } catch (err) {
          console.error('Failed to fetch pricing data', err);
          setError('Failed to fetch pricing data');
          setLoading(false);
        }
      }            

    fetchPricing();
  }, []);

  const filteredData = searchTerm
    ? pricingData.filter(instance =>
        instance.instanceType && instance.instanceType.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : pricingData;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSort = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortOrder('asc');
    }
    setPricingData(
      [...pricingData].sort((a, b) => {
        const aValue = a[key];
        const bValue = b[key];
        if (sortOrder === 'asc') {
          return aValue > bValue ? 1 : -1;
        }
        return aValue < bValue ? 1 : -1;
      })
    );
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Container className="mt-4">
      <h2 className="mb-4">Amazon EC2 Pricing</h2>

      <Form.Control
        type="text"
        placeholder="Search instance types..."
        value={searchTerm}
        onChange={handleSearch}
        className="mb-3"
      />
      
      <Row className="mb-3">
        <Col>
          <Button variant="primary" onClick={() => handleSort('instanceType')}>Sort by Instance Type</Button>{' '}
          <Button variant="secondary" onClick={() => handleSort('vCpu')}>Sort by vCPUs</Button>{' '}
          <Button variant="success" onClick={() => handleSort('memory')}>Sort by Memory (GiB)</Button>{' '}
          <Button variant="warning" onClick={() => handleSort('pricePerHour')}>Sort by Price per Hour</Button>
        </Col>
      </Row>
      
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Instance Type</th>
            <th>vCPUs</th>
            <th>Memory (GiB)</th>
            <th>Price per Hour</th>
          </tr>
        </thead>
        <tbody>
          {currentData.length > 0 ? (
            currentData.map((instance, index) => (
              <tr key={index}>
                <td>{instance.instanceType}</td>
                <td>{instance.vCpu}</td>
                <td>{instance.memory}</td>
                <td>{instance.pricePerHour}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">No data available</td>
            </tr>
          )}
        </tbody>
      </Table>
      
      <div className="d-flex justify-content-between mt-3">
        <Button variant="info" onClick={goToPreviousPage} disabled={currentPage === 1}>Previous</Button>
        <span>Page {currentPage} of {totalPages}</span>
        <Button variant="info" onClick={goToNextPage} disabled={currentPage === totalPages}>Next</Button>
      </div>
    </Container>
  );
}

export default EC2Pricing;
